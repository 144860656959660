<template>
  <div class="mt-5">
    <SignUpHead />
    <SignUpBody :user="user" />
  </div>
</template>

<style scoped lang="sass"></style>

<script>
import SignUpHead from "./components/SignUpHead.vue";
import SignUpBody from "./components/SignUpBody.vue";

export default {
  components: { SignUpHead, SignUpBody },
  name: "SignUpPage",
  data() {
    return {
      user: {
        departments: [],
        username: "",
        first_name: "",
        last_name: "",
        account_type: 2,
        phone: null,
        send_email: true,
        job_title: null,
        email: "",
      },
    };
  },
};
</script>
