<template>
  <div class="container mb-5">
    <div class="row" style="justify-content: center">
      <div class="col-md-5 mb-3">
        <label for="exampleFormControlInput1" class="form-label">Nome</label>
        <b-form-input
          v-model="user.first_name"
          placeholder="Nome"
          class="input"
        ></b-form-input>
      </div>
      <div class="col-md-5 mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Sobrenome</label
        >
        <b-form-input
          v-model="user.last_name"
          placeholder="Sobrenome"
          class="input"
        ></b-form-input>
      </div>
    </div>
    <div class="row mt-2" style="justify-content: center">
      <div class="col-md-5 mb-3">
        <label for="exampleFormControlInput1" class="form-label">E-mail</label>
        <b-form-input
          v-model="user.email"
          type="email"
          placeholder="Email"
          class="input"
        ></b-form-input>
      </div>
      <div class="col-md-5">
        <label for="inputState">Qual setor você trabalha?</label>
        <select id="inputState" v-model="user.department" class="form-control">
          <option :value="setor.id" v-for="(setor, i) in departments" :key="i">
            {{ setor.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mt-2" style="justify-content: center">
      <div class="col-md-5 mb-3">
        <label for="exampleFormControlInput1" class="form-label">Senha</label>
        <b-form-input
          v-model="user.password"
          :type="passwordFieldType"
          autocomplete="new-password"
          placeholder="Senha"
          class="input"
        ></b-form-input>

        <!-- botão de visualizar senha -->

        <i
          @click="switchVisibility"
          v-on:click="switchEye()"
          class="material-icons-outlined field-icon toggle-password"
          id="eyechange"
          >visibility_off
        </i>
      </div>
      <div class="col-md-5 mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Repita a senha</label
        >
        <b-form-input
          v-model="user.re_password"
          :type="passwordFieldType"
          autocomplete="new-password"
          placeholder="Senha"
          class="input"
        ></b-form-input>

        <!-- botão de visualizar senha -->

        <i
          @click="switchVisibility"
          v-on:click="switchEye()"
          class="material-icons-outlined field-icon toggle-password"
          id="eyechange"
          >visibility_off
        </i>
      </div>

      <div class="col-md-5"></div>
    </div>

    <div class="row mt-5" style="justify-content: flex-end">
      <b-button variant="danger" class="px-4" @click="createUser"
        >Criar</b-button
      >
      <div class="col-1"></div>
    </div>
  </div>
</template>

<style scoped lang="sass">
.field-icon
  color: #A9A9A9
  float: right
  margin-left: -25px
  margin-top: -29.5px
  margin-right: 10px
  position: relative
  z-index: 2
  cursor: pointer
</style>

<script>
import DepartmentService from "../../../services/DepartmentService/DepartmentService";

import UserService from "../../../services/UserService/UserService";
import Swal from "sweetalert2";

export default {
  name: "SignUpBody",
  props: ["user", "users"],
  data() {
    return {
      areas: [],
      areaService: null,
      departments: [],
      departmentService: null,
      passwordFieldType: "password",
    };
  },

  mounted() {
    this.userService = new UserService();
    this.departmentService = new DepartmentService();
    this.getAllDepartments();
  },

  methods: {
    async getAllDepartments() {
      this.departments = await this.departmentService.allDepartments();
    },

    async createUser() {
      console.log(this.user.account_type);
      if (this.user.password !== this.user.re_password) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "As senhas devem ser iguais!",
        });
        return;
      }

      await this.userService.createUserAnon(this.user);
    },

    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },

    switchEye() {
      var element = document.getElementById("eyechange");
      if (this.passwordFieldType === "password") {
        element.innerHTML = "visibility_off";
      } else {
        element.innerHTML = "visibility";
      }
    },
  },
};
</script>
